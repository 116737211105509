import React from 'react';

import Layout from '../components/furniture/Layout/Layout';

import generateTitle from '../utils/generateTitle';

const ThanksPage = ({ location }) => {

  const pageTitle = generateTitle('Thanks!');

  return (
    <Layout location={location} pageTitle={pageTitle}>
      <h1>Thanks!</h1>
      <p>Thanks for contacting us!</p>
    </Layout>
  );
};

export default ThanksPage;
